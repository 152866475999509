import R from "./ReferralProgram.module.css";

import { Suspense, useState, type ReactNode } from "react";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon,
} from "react-share";
import { WButton } from "../../../components/button/WButton.tsx";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { CurrencyPromo } from "../../../components/l10n/Currency.tsx";
import { NoticeBlock } from "../../../components/NoticeBlock.tsx";
import { useConfig } from "../../../config.ts";
import { cn } from "../../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../../api/resourceCreateLinks.ts";
import C from "../../dashboard/Dashboard.module.css";
import { eBillingAccountQueryAtom } from "../billingAccountQuery.ts";
import { getDefaultAccount, type EBillingAccount } from "../billingLogic.tsx";
import { BillingSelectCustom } from "../forms/BillingSelect.tsx";
import { getReferralUrl } from "./referralLogic.ts";

export interface ReferralProgramProps extends Omit<ReferralBoxProps, "account"> {
    items: EBillingAccount[];
}

export function ReferralProgramCustom({ items, ...boxProps }: ReferralProgramProps) {
    //#region Hooks
    const actualDefault = getDefaultAccount(items);
    const [account, setAccount] = useState(actualDefault);
    //#endregion

    const select = (
        <div className={R.First}>
            <div className="color-muted font-size-small pb-1">
                {items.length > 1 ? "Choose Billing Account" : "Billing Account"}
            </div>

            {items.length > 1 ? (
                <BillingSelectCustom
                    allowClosedAccounts
                    isItemDisabled={(item) => !item.canShareReferral}
                    onChange={setAccount}
                    items={items}
                    compact
                    isReferral
                />
            ) : (
                <div className={R.dummyField}>
                    <div className={cn(R.Label, "text-ellipsis")}>{account?.title ?? ""}</div>
                </div>
            )}
        </div>
    );

    return (
        <div className={C.Referral}>
            <h2 className="pb-3 pl-4">Referral Program</h2>

            {account ? (
                <ReferralBox withBackground {...boxProps} account={account} select={select} />
            ) : (
                <ReferralEmptyBox withBackground {...boxProps} />
            )}
        </div>
    );
}

export function ReferralEmptyBox({ copy, withBackground }: { withBackground?: boolean; copy?: ReactNode }) {
    return (
        <div className={cn(R.Block, withBackground ? R.Background : R.Custom)}>
            {!!copy && <p className="font-size-title pb-3">{copy}</p>}

            <div className={R.Share}>
                <NoticeBlock
                    color="primary"
                    icon="jp-warning-icon"
                    button={
                        <WButton color="primary" action={ResourceCreateLinks.billing_account}>
                            Create Billing Account
                        </WButton>
                    }
                >
                    An active billing account is needed to use this feature.
                </NoticeBlock>
            </div>
        </div>
    );
}

export interface ReferralBoxProps {
    account: EBillingAccount;

    withBackground?: boolean;
    copy?: ReactNode;
    select?: ReactNode;
    firstItem?: ReactNode;
}

export function ReferralBox({ account, select, firstItem, copy, withBackground }: ReferralBoxProps) {
    //#region Hooks
    const { firstAccountCredit, referralCreditAmounts, siteName } = useConfig();
    //#endregion

    const { referralShareCode } = account;
    if (!referralShareCode) {
        console.warn("Referral share code is missing");
        return null;
    }

    const referralUrl = getReferralUrl(referralShareCode);

    //#region Amounts
    const promoterCredit = referralCreditAmounts.promoter_credit;
    // TODO: This can be not set, should we display a different message then?
    const minimumTopUp = referralCreditAmounts.referral_credit_receiver_precondition_topup_sum ?? 0;
    const totalReceiverCredit = referralCreditAmounts.receiver_credit
        ? referralCreditAmounts.receiver_credit + firstAccountCredit
        : 0;
    //#endregion

    return (
        <div className={cn(R.Block, withBackground ? R.Background : R.Custom)}>
            {!!copy && <p className="font-size-title pb-3">{copy}</p>}

            <ul>
                {!!firstItem && <li>{firstItem}</li>}
                {account.isPostPay ? (
                    // TODO: Unify the text wording here to be more similar between the pre-pay and post-pay cases
                    <>
                        <li>
                            For every new user who signs up using your referral code and adds a verified payment method,
                            you will receive <CurrencyPromo value={promoterCredit} /> worth of free credits.
                        </li>
                        <li>
                            Every new user receives a <CurrencyPromo value={totalReceiverCredit} /> worth of free
                            credits when signing up through the referral link or code and adding a verified payment
                            method.
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            For every new user who signs up through the referral program and tops up at least{" "}
                            <CurrencyPromo value={minimumTopUp} />
                            , you will receive <CurrencyPromo value={promoterCredit} /> worth of free credits.
                        </li>
                        <li>
                            Every new user who signs up through the referral program and tops up at least{" "}
                            <CurrencyPromo value={minimumTopUp} /> will receive a bonus worth of{" "}
                            <CurrencyPromo value={totalReceiverCredit} /> credits.
                        </li>
                    </>
                )}
            </ul>

            <div className={R.Share}>
                {select}

                <div>
                    <div className="color-muted font-size-small pb-1">Referral Code</div>
                    <div className={R.dummyField}>
                        <ClipBoardTooltip>{referralShareCode}</ClipBoardTooltip>
                    </div>
                </div>

                <div className={R.Last}>
                    <div className="color-muted font-size-small pb-1">Referral Link</div>
                    <div className={R.dummyField}>
                        <ClipBoardTooltip>{referralUrl}</ClipBoardTooltip>
                    </div>
                </div>

                <div>
                    <div className="color-muted font-size-small pb-1">Social Share</div>

                    <div className="flex gap-0.5em">
                        <FacebookShareButton htmlTitle="Share on Facebook" className="share-button" url={referralUrl}>
                            <FacebookIcon size={36} borderRadius={4} />
                        </FacebookShareButton>
                        <TwitterShareButton
                            htmlTitle="Share on X"
                            className="share-button"
                            title={siteName}
                            url={referralUrl}
                        >
                            <XIcon size={36} borderRadius={4} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                            htmlTitle="Share on LinkedIn"
                            className="share-button"
                            title={siteName}
                            url={referralUrl}
                        >
                            <LinkedinIcon size={36} borderRadius={4} />
                        </LinkedinShareButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export interface ReferralProgramPropsLoaderProps extends Omit<ReferralProgramProps, "items"> {}

function ReferralProgramLoader({ ...props }: ReferralProgramPropsLoaderProps) {
    const data = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    return <ReferralProgramCustom items={[...data.values()]} {...props} />;
}

export function ReferralProgram(props: ReferralProgramPropsLoaderProps) {
    // NB: No fallback to prevent flickering between "no accounts" and "has accounts" designs
    return (
        <Suspense fallback={null}>
            <ReferralProgramLoader {...props} />
        </Suspense>
    );
}
